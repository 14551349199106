import GeneralizationChild from './generalizationChild';

export default class Generalization {
  constructor(model, id, type, entityId) {
    this.__type = 'Generalization';
    this._model = () => model;
    this._id = id;
    this._type = type;
    this._entityId = entityId;
  }
  static fromObject(model, obj) {
    return new Generalization(model, obj._id, obj._type, obj._entityId);
  }
  getId() {
    return this._id;
  }
  getType() {
    return this._type;
  }
  getEntity() {
    return this._model().getItemById(this._entityId);
  }
  getChildren() {
    return this._model().getItemsWhere(i => i instanceof GeneralizationChild && i.getGeneralization().getId() == this.getId());
  }
  getSupportedFunctionalities() {
    return {
      generalizationType: true,
      restructuring: true
    };
  }
  getAllowedFunctionalities() {
    let collapseIntoChildren = { ok: true };
    if(this.getType() != 't_e')
      collapseIntoChildren = { ok: false, error: 'This option is only allowed when the generalization is total and exclusive.' };
    
    return {
      collapseIntoChildren
    };
  }
  __beforeDelete() {
    for(let child of this.getChildren())
      this._model().deleteItem(child.getId());
  }
  setType(type) {
    this._type = type;
  }
  toERCode() {
    let code = `${this.getEntity().getName()} <= {\n    ${this.getChildren().map(c => c.getEntity().getName()).join(',\n    ')}\n} `;

    code += '(';
    code += this.getType().toUpperCase().replace('P', 'partial').replace('T', 'total').replace('E', 'exclusive').replace('O', 'overlapping').replace('_', ', ');
    code += ')';
    code=code.replace('(, )','')
    return code;
  }
}