import colors from './colors';
import DiagramGroup from './diagramGroup';
import DiagramText from './diagramText';
import { multipleIdentifiersCurve, externalIdentifierCurve } from '../../utils/erModel';

const drawRelationshipText = () => {
  let _text = new DiagramText();
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  return _text;
};

const drawRelationshipDiamond = (context, width, height) => {
  let _diamond = context.two.makePath(0, -height / 2, -width / 2, 0, 0, height / 2, width / 2, 0);
  _diamond.fill = context.diagram.transparent ? colors.WHITE : colors.BACKGROUND;
  _diamond.stroke = colors.BLACK;
  return _diamond;
};

const drawExternalIdCurve = (context, roundRect, outerCircleRadius, sa, ea) => {
  let _curve = context.two.makeRoundedRectangle(0, 0, roundRect.width, roundRect.height, roundRect.radius);
  _curve.fill = colors.TRANSPARENT;
  _curve.stroke = colors.BLACK;
  _curve.linewidth = 2;
  let _mask = context.two.makeArcSegment(0, 0, 0, outerCircleRadius, sa, ea);
  let _group = context.two.makeGroup(_curve);
  _group.mask = _mask;
  return _group;
};

const drawIdCircle = (context, x, y, radius) => {
  let _circle = context.two.makeCircle(x, y, radius);
  _circle.fill = colors.BLACK;
  _circle.stroke = colors.BLACK;
  return _circle;
};

const drawMultiIdCurve = (context, curvePoints) => {
  let _curve = context.two.makeCurve(...curvePoints, true);
  _curve.fill = colors.TRANSPARENT;
  _curve.stroke = colors.BLACK;
  _curve.linewidth = 2;
  return _curve;
};


const drawWarnLine = context => {
  let _rect = context.two.makeRectangle(0, 12, 0, 2);
  _rect.noStroke();
  return _rect;
};

class RelationshipGroup extends DiagramGroup {
  constructor(id, context) {
    super(id, context);
    this.erText = drawRelationshipText();
    this.erDiamondContainer = this.context.two.makeGroup();
    this.erAttrs = this.context.two.makeGroup();
    this.erWarn = drawWarnLine(this.context);
    this.erGeneralization = this.context.two.makeGroup();   
    this.erExternalIdCirclesContainer = this.context.two.makeGroup();
    this.erExternalIdContainer = this.context.two.makeGroup(this.erExternalIdCirclesContainer);
    this.erMultiIdCirclesContainer = this.context.two.makeGroup();
    this.erMultiIdContainer = this.context.two.makeGroup(this.erMultiIdCirclesContainer);
    this.add(this.erAttrs, this.erDiamondContainer, this.erExternalIdContainer, this.erText, this.erWarn, this.erMultiIdContainer);
  }
  getAttrs() {
    return this.erAttrs;
  }
  getShape() {
    return { x: this.translation.x, y: this.translation.y, width: this.erDiamondWidth, height: this.erDiamondHeight };
  }
  getGeneralization() {
    return this.erGeneralization;
  }
  setExternalIdentifierAttributes(attributes) {
    this.erExtIdAttrs = attributes;
    this.erExtIdRelationships = [];
    this.erExternalIdContainer.opacity = 0;
  }
  addExternalIdentifierRelationship(_relationship) {
    this.erExtIdRelationships.push(_relationship);
    this.erExternalIdContainer.opacity = 1;
    this.erExternalIdContainer.remove(this.erExternalIdCurve);
    const [roundRect, outerCircleRadius, sa, ea, points] = externalIdentifierCurve(this, this.erExtIdRelationships, this.erExtIdAttrs, 18, 8, 20);
    this.erExternalIdCurve = drawExternalIdCurve(this.context, roundRect, outerCircleRadius, sa, ea);
    this.erExternalIdCirclesContainer.remove(this.erExternalIdCirclesContainer.children);
    for(let i = 0; i < points.length / 2; i++)
        this.erExternalIdCirclesContainer.add(drawIdCircle(this.context, points[i*2], points[i*2 + 1], (i == points.length / 2 - 1) ? 8 : 4));
    this.erExternalIdContainer.add(this.erExternalIdCurve);
    
    this.erExternalIdCurve.children[0].stroke = this.erToBeTranslated ? colors.GRAY73 : colors.BLACK;
    for(let externalIdCircle of this.erExternalIdCirclesContainer.children)
      externalIdCircle.fill = externalIdCircle.stroke = this.erToBeTranslated ? colors.GRAY73 : colors.BLACK;
  }
  setIdentifiers(attributes) {
    this.erMultiIdContainer.opacity = attributes.length > 1 ? 1 : 0;
    this.erMultiIdContainer.remove(this.erMultiIdCurve);
    if(attributes.length > 1) {
      const curvePoints = multipleIdentifiersCurve(attributes, 25, 4);
      this.erMultiIdCurve = drawMultiIdCurve(this.context, curvePoints);
      this.erMultiIdCirclesContainer.remove(this.erMultiIdCirclesContainer.children);
      for(let i = 1; i < curvePoints.length / 2; i++)
        this.erMultiIdCirclesContainer.add(drawIdCircle(this.context, curvePoints[i*2], curvePoints[i*2 + 1], (i == curvePoints.length / 2 - 1) ? 8 : 4));
      this.erMultiIdContainer.add(this.erMultiIdCurve);
    }
  }
  setName(name) {
    this.erText.value = name;
    this.erText.size = name.length > 10 ? 16 : 17;
    const modelScene = this.context.diagram.scene.$model;
    const slope = 0.694 * Math.pow(0.9365, Math.max(3, name.length) / 3);
    const textWidth = this.erText.getBoundingClientRect().width / modelScene.scale;
    const paddedTextWidth = Math.max(120, textWidth + 20);
    this.erDiamondWidth = 28 / slope + paddedTextWidth;
    this.erDiamondHeight = slope * paddedTextWidth + 28;
    this.erDiamondContainer.remove(this.erDiamond);
    this.erDiamond = drawRelationshipDiamond(this.context, this.erDiamondWidth, this.erDiamondHeight);
    this.erDiamondContainer.add(this.erDiamond);
    this.erWarn.width = textWidth;
    this.erGeneralization = this.context.two.makeGroup();   
    this.add(this.erGeneralization)
  }
  setSelected(selected) {
    this.erText.weight = selected ? 600 : 400;
    this.erDiamond.linewidth = selected ? 3 : 2;
    this.erWarn.height = selected ? 4 : 2;
  }
  setWarningsVisible(visible, errors) {
    this.erWarn.fill = errors ? colors.RED : colors.YELLOW;
    this.erWarn.opacity = visible ? 1 : 0;
  }
  setToBeTranslated(toBeTranslated) {
    this.erDiamond.stroke = toBeTranslated ? colors.RED : colors.BLACK;
  }
}

export const drawRelationship = (relationship, context) => {
  const scene = context.diagram.scene;
  let _relationship = context.diagram.$(relationship.getId());
  
  const relationshipAttributes = relationship.getAttributes();

  const warningsAllowed = !context.diagram.transparent;
  const relationshipErrors = relationship.getErrors().filter(e => !e.scope || e.scope == context.diagram.step);
  const relationshipWarnings = relationship.getWarnings().filter(w => !w.scope || w.scope == context.diagram.step);
  
  const translating = context.diagram.step == 'erTranslation';

  if(!_relationship) {
    _relationship = new RelationshipGroup(relationship.getId(), context);
    context.diagram.register(relationship.getId(), _relationship);
    scene.$modelInner.add(_relationship);
  }

  _relationship.setName(relationship.getName());
  _relationship.setPosition(relationship.getX(), relationship.getY());
  _relationship.setIdentifiers(relationshipAttributes.filter(a => a.isIdentifier()));
  _relationship.setSelected(context.diagram.selectedItem?.getId() == relationship.getId());
  _relationship.setExternalIdentifierAttributes(relationshipAttributes.filter(a => a.isExternalIdentifier()));
  _relationship.setWarningsVisible(warningsAllowed && (relationshipErrors.length || relationshipWarnings.length), relationshipErrors.length);
  
  _relationship.setToBeTranslated(translating);
};