import colors from './colors';
import DiagramGroup from './diagramGroup';
import DiagramText from './diagramText';
import { participationSegment } from '../../utils/erModel';

const drawParticipationLine = (context, x1, y1, x2, y2) => {
  let _line = context.two.makeLine(x1, y1, x2, y2);
  _line.stroke = colors.BLACK;
  _line.linewidth = 2;
  return _line;
};

const drawSelectionLine = (context, x1, y1, x2, y2) => {
  let _line = context.two.makeLine(x1, y1, x2, y2);
  _line.stroke = colors.TRANSPARENT;
  _line.linewidth = 12;
  return _line;
};

const drawText = () => {
  let _text = new DiagramText();
  _text.family = 'Commissioner';
  _text.fill = colors.BLACK;
  _text.size = 18;
  return _text;
};

const drawTextBackground = context => {
  let _rect = context.two.makeRectangle(0, 0, 0, 24);
  _rect.fill = context.diagram.transparent ? colors.WHITE : colors.BACKGROUND;
  _rect.noStroke();
  return _rect;
};

class ParticipationGroup extends DiagramGroup {
  constructor(id, context) {
    super(id, context);
    this.erSegment = null;
    this.erLineContainer = this.context.two.makeGroup();
    this.erCardinalityText = drawText();
    this.erCardinalityTextBackground = drawTextBackground(this.context);
    this.erRoleText = drawText();
    this.erRoleTextBackground = drawTextBackground(this.context);
    this.add(this.erLineContainer, this.erRoleTextBackground, this.erRoleText, this.erCardinalityTextBackground, this.erCardinalityText);
  }
  setLine(_entity, _relationship, unary,flag) {
    const [x1, y1, x2, y2] = participationSegment(_entity, _relationship, unary);
    this.erSegment = { entityX: x1, entityY: y1, relationshipX: x2, relationshipY: y2 };
    this.erLineContainer.remove(this.erLine).remove(this.erSelectionLine);
    this.erLine = drawParticipationLine(this.context, x1, y1, x2, y2);
    this.erSelectionLine = drawSelectionLine(this.context, x1, y1, x2, y2);
    this.erLineContainer.add(this.erLine).add(this.erSelectionLine);
    this.erRoleText.translation.set((x1 + x2*2) / 3, (y1 + y2*2) / 3 + 2);
    this.erRoleTextBackground.translation.set((x1 + x2*2) / 3, (y1 + y2*2) / 3);
    if(flag){
      this.erCardinalityText.translation.set((x1*2 + x2) / 3, (y1*2 + y2) / 3 + 2);
      this.erCardinalityTextBackground.translation.set((x1*2 + x2) / 3, (y1*2 + y2) / 3);
      
    }
  }
  setCardinality(showCardinality,cardinality) {
    this.erCardinalityText.value = `(${cardinality.replace('_', ',')})`;
    const modelScene = this.context.diagram.scene.$model;
    this.erCardinalityTextBackground.width = this.erCardinalityText.getBoundingClientRect().width / modelScene.scale + 6;
    this.erCardinalityText.opacity = showCardinality ? 1 : 0;
  }
  setRole(role) {
    this.erRoleText.value = role;
    this.erRoleText.opacity = role.length ? 1 : 0;
    const modelScene = this.context.diagram.scene.$model;
    this.erRoleTextBackground.width = role.length ? this.erRoleText.getBoundingClientRect().width / modelScene.scale + 6 : 0;
  }
  setSelected(selected) {
    this.erLine.linewidth = selected ? 4 : 2;
    this.erRoleText.weight = selected ? 600 : 400;
    this.erCardinalityText.weight = selected ? 600 : 400;
  }
  setToBeTranslated(toBeTranslated) {
    this.erLine.stroke = toBeTranslated ? colors.GRAY73 : colors.BLACK;
    this.erCardinalityText.fill = toBeTranslated ? colors.GRAY60 : colors.BLACK;
    this.erRoleText.fill = toBeTranslated ? colors.GRAY60 : colors.BLACK;
  }
}

export const drawParticipation = (participation, context) => {
  const scene = context.diagram.scene;
  let _participation = context.diagram.$(participation.getId());
  const _et = context.diagram.$(participation.getGenericET().getId());
  const _relationship = context.diagram.$(participation.getRelationship().getId());
  
  const translating = context.diagram.step == 'erTranslation';

  if(!_participation) {
    _participation = new ParticipationGroup(participation.getId(), context);
    context.diagram.register(participation.getId(), _participation);
    scene.$modelParticipations.add(_participation);
  }

  _participation.setLine(_et, _relationship, participation.getUnaryIndex(), participation.getCardinality() != '_');
  _participation.setCardinality(participation.getCardinality() != '_',participation.getCardinality());
  _participation.setRole(participation.getRole());
  _participation.setSelected(context.diagram.selectedItem?.getId() == participation.getId());
  
  if(participation.isExternalIdentifier())
    _et.addExternalIdentifierRelationship(_relationship);

  _participation.setToBeTranslated(translating);
};