<template>
  <div>
  <p class="align"><button class="close-btn" @click="handleBack()">X</button></p>
    <div class="new-project">
      <h2>Create a new QT project</h2>   
      <p>If you create a new project, the current one will be lost</p>
      <p>Do you want to save the current project first?</p>
      <!--<input type="text" @input="handleInput($event)" @change="$emit('input-proj', $event)" placeholder="Insert the new project name" >-->
      <!--<p style="font-size:12px;" v-if="showError">Insert name before confirm</p>-->
      <div class="save">
        <button class="my-btn" @click="handleSave()">Save</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a ref="erOutputFile" class="hide" :download="pN+'.er'"></a>
        <button class="my-btn" @click="handleNotSave()">Don't save</button>
      </div>
    </div> 
  </div> 
</template>

<script>
import { ref, inject } from 'vue';
import { computed } from 'vue';
import { mainStore } from '../store/main';
import analytics from '../services/analytics';
import analyticsEvents from '../services/analytics/events';
import config from '../config';

export default {
  name: 'BugReports',
  data() {
    return {
      showError : false,
      showModal : mainStore.openModal
    }
  },

  methods : {
    handleNotSave(){
    mainStore.resetState();
    this.pN='ER project';
    mainStore.setShowNotesDataMenu(false);
    },
    
    handleSave(){
      this.saveToFile();
      mainStore.resetState();
      mainStore.closeModal();
      this.pN='ER project';
      mainStore.setShowNotesDataMenu(false);
    },
    handleBack(){
      mainStore.closeModal();
    }
  },
  setup() {
    let currentURL = null;
    const erOutputFile = ref(null);
    const pN = inject('pN');
    const { emailTo, emailSubject } = config.bugReports;
    const link = computed(() => {
      return `mailto:${emailTo}?subject=${emailSubject}`;
    })
    function saveToFile() {
      const data = mainStore.serializeState();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
      currentURL = window.URL.createObjectURL(new Blob([data]));
      erOutputFile.value.href = currentURL;
      erOutputFile.value.click();
      analytics.event(analyticsEvents.APP_SAVE_DESIGN);
    }    
    return {
      link,
      saveToFile,
      erOutputFile,
      pN
    };
  }
};
</script>

<style scoped>
.align{
  text-align: right;
  margin-bottom: 0px;
  margin-top: 7px;
}
.close-btn{
  background-color: transparent !important;
  font-size: 18px;
  font-weight: 800;
  padding: 4px 16px 0px 0px;
}
.my-btn{
  padding: 4px 10px;
  background: transparent;
  border: 2px solid #191716;
  border-radius: 10px;
  font-weight: 600;
  font-family: 'Commissioner', sans-serif;
  text-transform: uppercase;
  outline: none;
}
.save{
  margin: 2em 0 0 0;
}

input {
  height: 24px;
  background: transparent;
  border: 1px solid #acabab;
  border-radius: 6px; 
  outline: none;
  padding-block: 20px;  
}

input {
  padding: 0 3px 0 6px;
}
h2 {
  margin-top: 0px;
  margin-bottom: 1em;
  display: inline-block;
}
svg {
  width: 16px;
  vertical-align: bottom;
}
.new-project {
  text-align: center;
  padding: 0px 40px 40px 40px;
}

@media all and (max-width: 720px) {
  .new-project {
    font-size: 0.85em;
    padding: 10px 20px 40px 20px;
  }
}
</style>