import Entity from './entity';

export default class Attribute {
  constructor(model, id, name, identifier, externalIdentifier, cardinality, parentId, x, y) {
    this.__type = 'Attribute';
    this._model = () => model;
    this._id = id;
    this._name = name;
    this._identifier = identifier;
    this._externalIdentifier = externalIdentifier;
    this._cardinality = cardinality;
    this._parentId = parentId;
    this._x = Math.round(x);
    this._y = Math.round(y);
  }
  static fromObject(model, obj) {
    return new Attribute(model, obj._id, obj._name, obj._identifier, obj._externalIdentifier, obj._cardinality, obj._parentId, obj._x, obj._y);
  }
  getId() {
    return this._id;
  }
  getName() {
    return this._name;
  }
  isIdentifier() {
    return this._identifier;
  }
  isExternalIdentifier() {
    return this._externalIdentifier;
  }
  getX() {
    return this._x;
  }
  getY() {
    return this._y;
  }
  getCardinality() {
    if(this.getParent() instanceof Attribute)
      return this.getParent().getCardinality();
    return this._cardinality;
  }
  getMinCardinality() {
    return this.getCardinality().split('_')[0];
  }
  getMaxCardinality() {
    return this.getCardinality().split('_')[1];
  }
  getParent() {
    return this._model().getItemById(this._parentId);
  }
  getSubattributes() {
    return this._model().getItemsWhere(i => i instanceof Attribute && i.getParent().getId() == this.getId());
  }
  hasSubattributes() {
    return this.getSubattributes().length;
  }
  getSupportedFunctionalities() {
    return {
      attribute: this.getParent() instanceof Entity,
      identifier: !(this.getParent() instanceof Entity) || !this.getParent().hasExternalIdentifier(),
      externalIdentifier: this.getParent() instanceof Entity && this.getParent().hasExternalIdentifier(),
      cardinality: true,
      cardinalityN: true,
      moving: true,
      renaming: true,
      restructuring: (this.hasSubattributes() || this.getCardinality().endsWith('_N'))
    };
  }
  getAllowedFunctionalities() {
    let attribute = { ok: true };

    let identifier = { ok: true };

    let externalIdentifier = { ok: true };

    let cardinality = { ok: true };

    return {
      attribute,
      identifier,
      externalIdentifier,
      cardinality
    };
  }
  getErrors() {
    let errors = [];

    return errors;
  }
  getWarnings() {
    return [];
  }
  __beforeDelete() {
    for(let attribute of this.getSubattributes())
      this._model().deleteItem(attribute.getId());
  }
  setName(name) {
    name = name.replace(/\s/, '_').replace(/[^a-zA-Z0-9_]/, '').replace(/^\d+/, '');
    if(name.length)
      this._name = name;
    return name;
  }
  setIdentifier(identifier) {
    this._identifier = identifier;
    if(this.isIdentifier())
      this.setCardinality('1_1');
  }
  setExternalIdentifier(externalIdentifier) {
    this._externalIdentifier = externalIdentifier;
    if(this.isExternalIdentifier())
      this.setCardinality('1_1');
  }
  setCardinality(cardinality) {
    this._cardinality = cardinality;
  }
  move(dx, dy) {
    this._x = Math.round(this._x + dx);
    this._y = Math.round(this._y + dy);
  }
  toERCode() {
    let code = this.getName();
    let modifiers = [];

    if(this.isIdentifier())
      modifiers.push('id');
    if(this.isExternalIdentifier())
      modifiers.push('external');
    if(this.getCardinality().startsWith('0_'))
      modifiers.push('optional');
    if(this.getCardinality().endsWith('_N'))
      modifiers.push('multi');

    if(modifiers.length) {
      code += ' (';
      code += modifiers.join(', ');
      code += ')';
    }

    if(this.hasSubattributes()) {
      code += ' {\n        ';
      code += this.getSubattributes().map(a => a.toERCode()).join(',\n        ');
      code += '\n    }';
    }

    return code;
  }
}