<template>
  <div class="menu toolbox">
    <div><ToolboxButton text="Undo" icon="undo" :disabled="!canUndo" @click="mainStore.undo()" /></div>
    <div><ToolboxButton text="Redo" icon="redo" :disabled="!canRedo" @click="mainStore.redo()" /></div>
    <div class="sep"></div>
    <div v-if="!selectedItem">
      <div><ToolboxButton text="Entity" icon="entity" :selected="selectedTool == 'entity'" @click="store.toggleSelectedTool('entity')" /></div>
      <div><ToolboxButton text="Relation" icon="relationship" :selected="selectedTool == 'relationship'" @click="store.toggleSelectedTool('relationship')" /></div>
    </div>
    <div v-if="selectedItem">
      <div v-if="supported.parentEntity">
        <ToolboxButton text="Parent" icon="entity" :errorTooltip="allowed.parentEntity.error" :disabled="!allowed.parentEntity.ok" :selected="selectedTool == 'parent'" @click="store.toggleSelectedTool('parent')" />
      </div>
      <div v-if="supported.entityParticipation">
        <ToolboxButton text="Connect" icon="entity" :errorTooltip="allowed.entityParticipation.error" :disabled="!allowed.entityParticipation.ok" :selected="selectedTool == 'connect'" @click="store.toggleSelectedTool('connect')" />
      </div>
      <div v-if="supported.attribute">
        <ToolboxButton :text="isAttribute ? 'Subattr' : 'Attribute'" icon="attribute" :errorTooltip="allowed.attribute.error" :disabled="!allowed.attribute.ok" @click="store.addAttribute()" />
      </div>
      <div v-if="supported.identifier">
        <ToolboxButton text="Identifier" icon="identifier" :errorTooltip="allowed.identifier.error" :disabled="!allowed.identifier.ok" @click="store.toggleIdentifier()" />
      </div>
      <div v-if="supported.externalIdentifier">
        <ToolboxButton text="Ext. Id" icon="identifier" :errorTooltip="allowed.externalIdentifier.error" :disabled="!allowed.externalIdentifier.ok" @click="store.toggleExternalIdentifier()" />
      </div>
      <div class="submenu-container" v-if="supported.cardinality">
        <div><ToolboxButton text="Card." :icon="'card_' + selectedItemCardinality" :errorTooltip="allowed.cardinality.error" :disabled="!allowed.cardinality.ok" :selected="showCardinalityMenu" @click="store.toggleMenu('cardinality')" /></div>
        <div class="submenu-align">
          <div class="submenu" v-if="showCardinalityMenu">
            <div><ToolboxButton text="Optional" icon="card_0_1" @click="store.setCardinality('0_1')" /></div>
            <div><ToolboxButton text="Default" icon="card_1_1" @click="store.setCardinality('1_1')" /></div>
            <div v-if="supported.cardinalityN">
              <div><ToolboxButton text="MultiOpt" icon="card_0_N" @click="store.setCardinality('0_N')" /></div>
              <div><ToolboxButton text="Multi" icon="card_1_N" @click="store.setCardinality('1_N')" /></div>
            </div>
          </div>
        </div>
      </div>
      <div class="submenu-container" v-if="supported.generalizationType">
        <div><ToolboxButton text="Type" :icon="'gen_' + selectedItemGeneralizationType" :selected="showGeneralizationTypeMenu" @click="store.toggleMenu('generalizationType')" /></div>
        <div class="submenu-align">
          <div class="submenu" v-if="showGeneralizationTypeMenu">
            <div><ToolboxButton text="Part.Excl." icon="gen_p_e" @click="store.setGeneralizationType('p_e')" /></div>
            <div><ToolboxButton text="Part.Over." icon="gen_p_o" @click="store.setGeneralizationType('p_o')" /></div>
            <div><ToolboxButton text="Tot.Excl." icon="gen_t_e" @click="store.setGeneralizationType('t_e')" /></div>
            <div><ToolboxButton text="Tot.Over." icon="gen_t_o" @click="store.setGeneralizationType('t_o')" /></div>
          </div>
        </div>
      </div>
      <div class="rename-container" v-if="supported.role">
        <ToolboxButton text="Role" icon="rename" :selected="showRenamingRoleInput" @click="store.toggleMenu('renamingRole')" />
        <div class="rename-input-container" v-if="showRenamingRoleInput">
          <input type="text" spellcheck="false" placeholder="Participation role" v-focusselect="showRenamingRoleInput"  @input="renameRole($event)" @keydown="onRenameKeydown($event)" />
        </div>
      </div>
      <div class="sep"></div>
      <div class="rename-container" v-if="supported.renaming">
        <ToolboxButton text="Rename" icon="rename" :selected="showRenamingInput" @click="store.toggleMenu('renaming')" />
        <div class="rename-input-container" v-if="showRenamingInput">
          <input type="text" spellcheck="false" placeholder="New name" v-focusselect="showRenamingInput" @input="renameItem($event)" @keydown="onRenameKeydown($event)" />
        </div>
      </div>
      <div><ToolboxButton text="Delete" icon="delete" @click="store.deleteItem()" /></div>
    </div>
    <div v-if="!selectedItem">
      <div class="sep"></div>
      <div class="submenu-container">
        <ToolboxButton text="Export" icon="export" errorTooltip="The model must be non-empty in order for you to export it." :disabled="!canExport" :selected="showExportMenu" @click="store.toggleMenu('export')" />
        <div class="submenu-align">
          <div class="submenu" v-if="showExportMenu">
            <div><ToolboxButton text="PNG" icon="image" @click="saveAsPng()" /></div>
            <div><ToolboxButton text="TXT" icon="code" @click="saveAsTxt()" /></div>
            <div><ToolboxButton text="Notes" icon="notes" @click="saveAsNotes()" errorTooltip="Notes must be non-empty in order for you to export them" :disabled="!mainStore.getEditorData() || mainStore.getEditorData().html == '' "/></div>
          </div>
        </div>
      </div>
      <ToolboxButton  ref="childComponent" text="Save"  icon="save"  @click="saveToFile()" errorTooltip="The model must be non-empty to be saved"   @input-proj="inputProj($event)"/>
      <a ref="outputPngFile" id="pName" class="hide"  v-bind:pName="pName" :download="pN+'.png'"></a>
      <div ref="outputPngCanvas" class="hide"></div>
      <a ref="outputTxtFile" class="hide" download="schema.txt"></a>
      <a ref="outputTxtFile" class="hide" :download="pN+'.txt'"></a>
      <a ref="erOutputFile"  id="pName" class="hide" :download="pN+'.er'"></a>
      <a ref="outputNotesFile" class="hide" :download="pN+'.txt'"></a>
    </div>
  </div>
</template>

<script>
import { computed, ref, inject } from 'vue';

import ToolboxButton from './ToolboxButton';

import Attribute from '../lib/attribute';
import { getContext } from '../lib/editor';
import analytics from '../services/analytics';
import analyticsEvents from '../services/analytics/events';
import { mainStore } from '../store/main';

export default {
  name: 'ERModelEditorToolbox',
  components: {
    ToolboxButton
  },
  props: ['pName'],
  directives: {
    focusselect: {
      mounted(el) {
        el.focus();
        el.select();
      }
    }
  },
  created() {
    window.addEventListener('keydown', this.onKeyDown);        
    window.addEventListener('keypress', this.onKeyPress);
    window.addEventListener('click', this.onClickOver);
    window.addEventListener('dblclick', this.onDoubleClick);
  },
  methods:{
    onClose() {
      this.$emit("dialog-closed");
      this.onClickOver(null);
    },

    onKeyDown(e) {
      const step = mainStore.$step();
      if(step.value=='erDesign' && !this.showRenamingInput){
        if( (e.ctrlKey && e.key == 's') || (e.ctrlKey  && e.key == 'S') && !this.empty && this.getSelectedItem()==null){
          e.preventDefault();
          this.saveToFile();
        }
        if( (e.ctrlKey && e.key == 'z') || (e.ctrlKey  && e.key == 'Z') && !this.empty){
            e.preventDefault();
            mainStore.undo();
          }
        if( (e.ctrlKey && e.key == 'y') || (e.ctrlKey  && e.key == 'Y') && !this.empty){
            e.preventDefault();
            mainStore.redo();
          }
        if( (e.ctrlKey && e.key == 'p') || (e.ctrlKey  && e.key == 'P') && this.canExport && this.getSelectedItem()==null){
          e.preventDefault();
          this.saveAsPng();
          }
        if( (e.ctrlKey && e.key == 't') || (e.ctrlKey  && e.key == 'T')){
          e.preventDefault();
          this.saveAsTxt();
          }
        if( (e.ctrlKey && e.key == 'N') || (e.ctrlKey  && e.key == 'n')){
          e.preventDefault();
        }
    
      if(this.getSelectedItem()!=null){      
        if(this.getSelectedItem().__type =='Entity' && this.allowed){
          if( (e.shiftKey  && e.key == 'P') || (e.shiftKey  && e.key == 'p') && this.allowed.parentEntity.ok){
            e.preventDefault();
            this.store.toggleSelectedTool('parent');   
          }  
          if( (e.shiftKey && e.key == 'A') || (e.shiftKey  && e.key == 'a') && this.allowed.attribute.ok){
            e.preventDefault();
            this.store.addAttribute();
          }
      }
          if(this.getSelectedItem().__type =='Relationship'  && this.allowed){ //this.getSelectedItem().getAllowedFunctionalities().entityParticipation.ok
            if( (e.shiftKey && e.key == 'C') || (e.shiftKey  && e.key == 'c') && this.allowed.entityParticipation.ok ) {
          e.preventDefault();
          this.store.selectTool('connect');
        }
       
          if( (e.shiftKey && e.key == 'A') || (e.shiftKey  && e.key == 'a')  && this.allowed.attribute.ok ){
            e.preventDefault();
            this.store.addAttribute();
          }
        }
        if(this.getSelectedItem().__type =='Attribute'){          
            if( (e.shiftKey && e.key == 'S')  || (e.shiftKey  && e.key == 's') && this.supported && this.allowed.attribute.ok && !this.allowed.attribute.error && this.supported.attribute) {
          e.preventDefault();
          this.store.addAttribute();
        }
      
            if( (e.shiftKey && e.key == 'I') || (e.shiftKey  && e.key == 'i') && this.allowed.identifier.ok ){
            e.preventDefault();
            this.store.toggleIdentifier();
          }
            if( (e.shiftKey && e.key == 'C') || (e.shiftKey  && e.key == 'c')  && this.allowed.cardinality.ok ){
            e.preventDefault();
            this.store.toggleMenu('cardinality');
          }
        }
      }
      } 
      },

      onDoubleClick(){
        if( this.disabled!=true && this.selectedItem!=null && (this.selectedItem.__type =='Entity' || this.selectedItem.__type =='Relationship' || this.selectedItem.__type =='Attribute') ){//&& this.selectedItem.getName().match(/[a-zA-Z_]/g)=='T,a,b,l,e'){
        this.store.toggleMenu('renaming');
        }
      }
    },
  setup() {
    let currentURL = null;

    const outputPngFile = ref(null);
    const outputPngCanvas = ref(null);
    const outputTxtFile = ref(null);
    const erOutputFile = ref(null);

    const erDesignStore = mainStore.erDesign;
    const state = erDesignStore.getState();
    const canUndo = mainStore.$canUndo();
    const canRedo = mainStore.$canRedo();
    const selectedItem = erDesignStore.$selectedItem();
    const selectedTool = erDesignStore.$selectedTool();
    const showRenamingInput = erDesignStore.$renamingInput();
    const showRenamingRoleInput = erDesignStore.$renamingRoleInput();
    const showCardinalityMenu = erDesignStore.$cardinalityMenu();
    const showGeneralizationTypeMenu = erDesignStore.$generalizationTypeMenu();
    const showExportMenu = erDesignStore.$exportMenu();
    const canTranslate = erDesignStore.$canTranslate();
    const canExport = erDesignStore.$canExport();

    const supported = computed(() => selectedItem.value.getSupportedFunctionalities());
    const allowed = computed(() => selectedItem.value.getAllowedFunctionalities());
    const isAttribute = computed(() => selectedItem.value instanceof Attribute);
    const selectedItemCardinality = computed(() => selectedItem.value.getSupportedFunctionalities().cardinality && selectedItem.value.getCardinality());
    const selectedItemGeneralizationType = computed(() => selectedItem.value.getSupportedFunctionalities().generalizationType && selectedItem.value.getType());

    const pN = inject('pN');
    const outputNotesFile = ref(null);

    function saveToFile() {
      if(this.getSelectedItem()==null){
        const data = mainStore.serializeState();
        if(currentURL)
          window.URL.revokeObjectURL(currentURL);
        currentURL = window.URL.createObjectURL(new Blob([data]));
        erOutputFile.value.href = currentURL;
        erOutputFile.value.click();
        analytics.event(analyticsEvents.APP_SAVE_DESIGN);
      }
    }
    function renameItem(e) {
      erDesignStore.renameItem(e.target.value);
      e.target.value = erDesignStore.getRenamingValue();
    }
    function renameRole(e) {
      erDesignStore.setRole(e.target.value);
      e.target.value = erDesignStore.getRenamingValue();
    }
    function translate() {
      if(state.model.needsRestructuring())
        mainStore.moveToRestructuring();
      else
        mainStore.moveToTranslation();
    }
    async function saveAsPng() {
      if(this.getSelectedItem()==null){
        erDesignStore.toggleMenu();
        if(currentURL)
          window.URL.revokeObjectURL(currentURL);
        const context = getContext('erDesign');
        currentURL = window.URL.createObjectURL(new Blob([await context.exportPng(outputPngCanvas.value)]));
        outputPngFile.value.href = currentURL;
        outputPngFile.value.click();
        analytics.event(analyticsEvents.ER_SCHEMA_EXPORT_PNG);
      }
    }
    function saveAsTxt() {
        if(this.getSelectedItem()==null){
        erDesignStore.toggleMenu();
        if(currentURL)
          window.URL.revokeObjectURL(currentURL);
        currentURL = window.URL.createObjectURL(new Blob([erDesignStore.getCode()]));
        outputTxtFile.value.href = currentURL;
        outputTxtFile.value.click();
        analytics.event(analyticsEvents.ER_SCHEMA_EXPORT_TXT);
      }
    }
    function saveAsNotes() {
      if(mainStore.getEditorData()){
      erDesignStore.toggleMenu();
      if(currentURL)
        window.URL.revokeObjectURL(currentURL);
       //replace(/(<([^>]+)>)/ig, '');      
      currentURL = window.URL.createObjectURL(new Blob([mainStore.getEditorData().replace(/(<([^>]+)>)/ig, '')]));
      outputNotesFile.value.href = currentURL;
      outputNotesFile.value.click();
      }
    }
    function onRenameKeydown(e) {
      if(e.which == 13 || e.which == 27)
        erDesignStore.toggleMenu();
    }
    function getSelectedItem(){
        return erDesignStore.getSelectedItem();
    }

    return {
      store: erDesignStore,
      mainStore,
      canUndo,
      canRedo,
      showRenamingInput,
      showRenamingRoleInput,
      showCardinalityMenu,
      showGeneralizationTypeMenu,
      showExportMenu,
      outputPngFile,
      outputPngCanvas,
      outputTxtFile,
      selectedItem,
      selectedTool,
      canTranslate,
      canExport,
      supported,
      allowed,
      isAttribute,
      selectedItemCardinality,
      selectedItemGeneralizationType,
      renameItem,
      renameRole,
      translate,
      saveAsPng,
      saveAsTxt,
      onRenameKeydown,
      saveToFile,
      erOutputFile,
      pN,
      saveAsNotes,
      outputNotesFile,
      getSelectedItem,
    };
  }
};
</script>