<template>
  <div class="layout-container" v-if="showIsPressed">
    <div><ToolboxButton text="Hide" icon="into_left" @click="hideClicked" class="hideButton" /></div>
    <div class="code flex flex-col" v-bind:class="{ minimized: showModelToggleOn}" @click="codeClicked" >
      <div class="header code-h"><PanelTitle>{{ codeHeaderTitle }}</PanelTitle></div>
      <div class="content"><slot name="code"></slot></div>
    </div>
    <div class="model flex flex-col" v-bind:class="{ minimized: !showModelToggleOn }" @click="modelClicked">
      <div class="header model-h"><PanelTitle>{{ modelHeaderTitle }}</PanelTitle></div>
      <div class="content" ><slot name="model"></slot></div>
    </div>
  </div>
  <div class="layout-container" v-if="!showIsPressed">
    <div><ToolboxButton text="Show" icon="into_right" @click="hideClicked" class="hideButton" /></div>
    <div class="model-max flex flex-col" v-bind:class="{ minimized: showModelToggleOn }" @click="modelClicked">
      <div class="header model-h"><PanelTitle>{{ modelHeaderTitle }}</PanelTitle></div>
      <div class="maximised" ><slot name="model"></slot></div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted, onMounted  } from 'vue';

import PanelTitle from './PanelTitle';
import ToolboxButton from './ToolboxButton';

export default {
  name: 'CodeModelLayout',
  props: ['codeHeaderTitle', 'modelHeaderTitle','icon'],
  components: {
    PanelTitle,
    ToolboxButton
  },
  setup() {
    const showModelToggleOn = ref(true);
    const isMobile = ref(window.innerWidth <= 720 );
    const showIsPressed = ref(isMobile.value);

    function codeClicked() {
      showModelToggleOn.value = false;
    }
    function modelClicked() {
      showModelToggleOn.value = true;
    }
    function hideClicked(){
      if (showIsPressed.value){
        showIsPressed.value=false;
      }
      else{
        showIsPressed.value=true;
       
      }
    }
    function handleResize() {
      isMobile.value = window.innerWidth <= 720;
      showIsPressed.value = isMobile.value;
    }
    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });


    return {
      showModelToggleOn,
      codeClicked,
      modelClicked,
      hideClicked,
      showIsPressed,
      isMobile
    };
  }
};
</script>

<style scoped>
.layout-container {
  display: flex;
  height: 100%;
}
.content {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.code {
  width: 26%;
  padding: 10px 0 10px 20px;
  z-index: 1;
  border-right: 1px solid #bbb;

}

.model {
  width: 74%;
  position: relative;
  padding: 10px 20px;
}
.model-max {
  width: 100%;
  position: relative;
  padding: 10px 20px;
}

.maximised{
  display: flex;
  flex: 1;
  overflow: hidden;
  width: 100%;
}

.hideButton {
  position: relative;
  font-size: 2em;
  text-align: center;
  visibility: visible;
  
  }
.code-h, .model-h {
  color: #aaa;
}

@media all and (max-width: 720px) {
  .code:not(.minimized), .model:not(.minimized) {
    width: calc(100% - 24px);
  }
  .hideButton:not(.minimized){
    display: none;

  }

  .minimized {
    width: 24px;
    padding: 50% 0;
    background: #007acc;
    writing-mode: vertical-lr;
    text-orientation: upright;
  }
  .minimized  {
    width: 24px;
    padding: 50% 0;
    background: #007acc;
    writing-mode: vertical-lr;
    text-orientation: upright;
  }
  .minimized .header {
    margin: 0;
  }
  .minimized .content {
    display: none;
  }
  
}
</style>
<style>
@media all and (max-width: 720px ) {
  .minimized .editor {
    display: none;
  }

  .minimized .header p {
    margin: 0 0.3em;
    color: #fff;
  }
}
</style>