<template>
  <DiagramEditor contextName="erTranslation" :translating="true" :store="store">
    <div v-if="!showNotes">
      <div><ToolboxButton text="Notes" icon="into_left" @click="mainStore.toggleNotesDataMenu()" class="notesButton"/></div>
    </div>
    <div v-if="showNotes">
      <div><ToolboxButton text="Hide" icon="into_right" @click="mainStore.toggleNotesDataMenu()" class="notesButton" /></div>
      <div class="my-editor"><editor spellcheck="false" @change="readEditor($event)" :value="readData()"/></div>
    </div>
    <ERModelTranslateToolbox />
    <ErrorStatusBar :errors="selectedItemErrors" :warnings="selectedItemWarnings" />
  </DiagramEditor>
</template>

<script>
import DiagramEditor from './DiagramEditor';
import ERModelTranslateToolbox from './ERModelTranslateToolbox';
import ErrorStatusBar from './ErrorStatusBar';

import { getContext } from '../lib/editor';
import { mainStore } from '../store/main';
import ToolboxButton from './ToolboxButton.vue';
import { quillEditor } from 'vue3-quill'// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: 'ERModelTranslate',
  components: {
    DiagramEditor,
    ERModelTranslateToolbox,
    ErrorStatusBar,
    ToolboxButton,
    'editor': quillEditor,
  },
  setup() {
    const store = mainStore.erTranslation;
    const selectedItemWarnings = store.$selectedItemWarnings();
    const selectedItemErrors = store.$selectedItemErrors();
    const showNotes = mainStore.$showNotesDataMenu();
    
    getContext('erTranslation').setCallbacks({
      undo: () => mainStore.undo(),
      redo: () => mainStore.redo(),
      selectItem: id => store.selectItem(id),
      moveItem: (dx, dy) => store.moveItem(dx, dy),
      endMoveItem: () => store.finishedMovingItem()
    });

    function readEditor(e){
        mainStore.setEditorData(e);
      }
      function readData(){        
        return mainStore.getEditorData();
      }

    return {
      store,
      selectedItemWarnings,
      selectedItemErrors,
      showNotes,
      readEditor,
      readData,
      mainStore
    };
  }
};
</script>
<style scoped>
.notesButton {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 2em;
  text-align: center;
  visibility: visible; 
}
.my-editor{
  position: absolute;
  right: 0px;
  top: 60px;
  width: 500px;
  height: 100%;
  background: white;
}
</style>