import Attribute from './attribute';
import Entity from './entity';
import Participation from './participation';
import GeneralizationChild from './generalizationChild';
import Generalization from './generalization';

export default class Relationship {
  constructor(model, id, name, x, y) {
    this.__type = 'Relationship';
    this._model = () => model;
    this._id = id;
    this._name = name;
    this._x = Math.round(x);
    this._y = Math.round(y);
  }
  static fromObject(model, obj) {
    return new Relationship(model, obj._id, obj._name, obj._x, obj._y);
  }
  getId() {
    return this._id;
  }
  getName() {
    return this._name;
  }
  getX() {
    return this._x;
  }
  getY() {
    return this._y;
  }
  getAttributes() {
    return this._model().getItemsWhere(i => i instanceof Attribute && i.getParent().getId() == this.getId());
  }
  hasAttributes() {
    return this.getAttributes().length;
  }
  getParticipations() {
    return this._model().getItemsWhere(i => i instanceof Participation && i.getRelationship().getId() == this.getId());
  }
  
  hasParticipations() {
    return this.getParticipations().length;
  }
  generalization() {
    return this._model().getItemWhere(i => i instanceof Generalization && i.getEntity().getId() == this.getId());
  }
  generalizationChild() {
    return this._model().getItemWhere(i => i instanceof GeneralizationChild && i.getEntity().getId() == this.getId());
  }
  getParentEntity() {
    return this.generalizationChild()?.getGeneralization().getEntity();
  }
  isRecursive() {
    return this.getParticipations().length == 2 && new Set(this.getParticipations().map(p => p.getGenericET())).size == 1;
  }
  isTernary() {
    return this.getParticipations().length == 3;
  }
  isManyToMany() {
    return this.getParticipations().length == 2 && this.getParticipations().map(p => p.getMaxCardinality()).every(c => c == 'N');
  }
  isOneToMany() {
    return this.getParticipations().length == 2 && this.getParticipations().map(p => p.getMaxCardinality()).sort().join('_') == '1_N';
  }
  isOneToOne() {
    return this.getParticipations().length == 2 && this.getParticipations().map(p => p.getMaxCardinality()).every(c => c == '1');
  }
  canAddParticipation(entity) {
    if(!(entity instanceof Entity || entity instanceof Relationship))
      return { result: false };
    if(this.getParticipations().length == 2 && !this.isRecursive() && this.getParticipations().some(p => p.getEntity().getId() == entity.getId()))
      return { result: false, error: 'The entity selected to participate in the relationship is not a valid choice.' };
    return { result: true };
  }
  getSupportedFunctionalities() {
    return {
      entityParticipation: true,
      attribute: true,
      moving: true,
      renaming: true,
      translating: true
    };
  }
  getAllowedFunctionalities() {
    let entityParticipation = { ok: true };

    let attribute = { ok: true };

    return {
      attribute,
      entityParticipation
    };
  }
  getErrors() {
    let errors = [];
    return errors;
  }
  getWarnings() {
    return [];
  }
  __beforeDelete() {
    for(let attribute of this.getAttributes())
      this._model().deleteItem(attribute.getId());

    for(let participation of this.getParticipations())
      this._model().deleteItem(participation.getId());
  }
  setName(name) {
    name = name.replace(/\s/, '_').replace(/[^a-zA-Z0-9_]/, '').replace(/^\d+/, '').toUpperCase();
    if(name.length)
      this._name = name;
    return name;
  }
  move(dx, dy) {
    this._x = Math.round(this._x + dx);
    this._y = Math.round(this._y + dy);
  }
  resetExternalIdentifierAttributes() {
    for(let attribute of this.getAttributes())
      attribute.setExternalIdentifier(false);
  }
  toERCode() {
    let code = `relationship ${this.getName()}`;

    if(this.hasParticipations()) {
      code += ' (\n    ';
      code += this.getParticipations().map(p => p.toERCode()).join(',\n    ');
      code += '\n)';
    }
    if(this.hasAttributes()) {
      code += ' {\n    ';
      code += this.getAttributes().map(a => a.toERCode()).join(',\n    ');
      code += '\n}';
    }

    return code;
  }
}