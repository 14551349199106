import Attribute from './attribute';
import Column from './column';
import Entity from './entity';
import ForeignKey from './foreignKey';
import Generalization from './generalization';
import GeneralizationChild from './generalizationChild';
import Participation from './participation';
import Relationship from './relationship';
import Table from './table';
import columnTypes from './columnTypes';
import * as restrComposite from './restructuring/compositeAttribute';
import * as restrGeneralization from './restructuring/generalization';
import * as restrMultivalued from './restructuring/multivaluedAttribute';
import * as transEntity from './translation/entity';
import * as transManyToMany from './translation/manyToManyRelationship';
import * as transOneToMany from './translation/oneToManyRelationship';
import * as transOneToOne from './translation/oneToOneRelationship';
import { idealAttributePosition } from './utils/erModel';

const itemClasses = {
  Attribute,
  Column,
  Entity,
  ForeignKey,
  Generalization,
  GeneralizationChild,
  Participation,
  Relationship,
  Table
};

export default class Model {
  constructor(uid = 1, erCode = '', sqlCode = '', items = []) {
    this.uid = uid;
    this.erCode = erCode;
    this.sqlCode = sqlCode;
    this.itemsArray = [];
    this.itemsMap = {};

    for(let item of items) {
      this.itemsArray.push(item);
      this.itemsMap[item.getId()] = item;
    }
  }
  static fromObject(obj) {
    let items = obj.itemsArray || [];
    let model = new Model(obj.uid, obj.erCode, obj.sqlCode, []);
    for(let item of items)
      model._addItem(itemClasses[item.__type].fromObject(model, item));
    return model;
  }
  _uid() {
    return this.uid++;
  }
  _addItem(item) {
    this.itemsArray.push(item);
    this.itemsMap[item.getId()] = item;
    return item;
  }
  _deleteItem(id) {
    const i = this.itemsArray.findIndex(i => i.getId() == id);
    if(i != -1)
      this.itemsArray.splice(i, 1);
    delete this.itemsMap[id];
  }
  getERCode() {
    return this.erCode;
  }
  getSQLCode() {
    return this.sqlCode;
  }
  getItems() {
    return this.itemsArray;
  }
  getItemById(id) {
    return id ? this.itemsMap[id] : null;
  }
  getItemWhere(filter) {
    return this.itemsArray.find(filter) || null;
  }
  getItemsWhere(filter) {
    return this.itemsArray.filter(filter);
  }
  isEmpty() {
    return this.itemsArray.length == 0;
  }
  hasErrors() {
    return this.itemsArray.some(i => i.getErrors && i.getErrors().length);
  }
  needsRestructuring() {
    return this.itemsArray.some(i => i.getSupportedFunctionalities().restructuring);
  }
  needsTranslation() {
    return this.itemsArray.some(i => i.getSupportedFunctionalities().translating);
  }
  setERCode(code) {
    this.erCode = code;
  }
  setSQLCode(code) {
    this.sqlCode = code;
  }
  addEntity(name, x, y, generatedFromMultivaluedAttribute = false) {
    const id = this._uid();
    name = name || `ENTITY${id}`;
    return this._addItem(new Entity(this, id, name, x, y, generatedFromMultivaluedAttribute));
  }
  addRelationship(name, x, y) {
    const id = this._uid();
    name = name || `RELATIONSHIP${id}`;
    return this._addItem(new Relationship(this, id, name, x, y));
  }
  addAttribute(name, parentId, identifier = false, externalIdentifier = false, cardinality = '1_1', x = null, y = null) {
    const id = this._uid();
    name = name || `Attribute${id}`;
    if(x == null || y == null) {
      const attributePosition = idealAttributePosition(this, this.getItemById(parentId));
      x = attributePosition.x;
      y = attributePosition.y;
    }
    return this._addItem(new Attribute(this, id, name, identifier, externalIdentifier, cardinality, parentId, x, y));
  }
  addParticipation(entityId, tableId, relationshipId, cardinality = '_', externalIdentifier = false, role = '') {
    if(entityId) {
      const { result } = this.getItemById(relationshipId).canAddParticipation(this.getItemById(entityId));
      if(result){
        return this._addItem(new Participation(this, this._uid(), entityId, tableId, relationshipId, cardinality, externalIdentifier, role));
      }
    }
  }
  addGeneralization(parentEntityId, childEntityId) {
    const { result } = this.getItemById(childEntityId).canAddGeneralization(this.getItemById(parentEntityId));
    if(result){
      
      let generalizationId = this.getItemWhere(i => i instanceof Generalization && i.getEntity().getId() == parentEntityId)?.getId();
      if(!generalizationId) {
        generalizationId = this._uid();
        this._addItem(new Generalization(this, generalizationId, '_', parentEntityId));
      }
    
    return this._addItem(new GeneralizationChild(this, this._uid(), childEntityId, generalizationId));
    }
  }
  addTable(name, x, y, generatedFromMultivaluedAttribute = false) {
    return this._addItem(new Table(this, this._uid(), name, x, y, generatedFromMultivaluedAttribute));
  }
  addColumn(name, tableId, tableIndex, identifier = false, nullable = false) {
    return this._addItem(new Column(this, this._uid(), name, columnTypes.INTEGER, identifier, false, nullable, tableId, tableIndex));
  }
  addForeignKey(columnAId, columnBId) {
    return this._addItem(new ForeignKey(this, this._uid(), columnAId, columnBId));
  }
  deleteItem(id) {
    const item = this.getItemById(id);
    if(item) {
      item.__beforeDelete?.();
      this._deleteItem(id);
      item.__afterDelete?.();
    }
  }
  restructureMultivalueAttribute(attributeId, unique = true) {
    restrMultivalued.restructureMultivalueAttribute(this, attributeId, unique);
  }
  splitCompositeAttribute(attributeId) {
    restrComposite.splitCompositeAttribute(this, attributeId);
  }
  mergeCompositeAttribute(attributeId) {
    restrComposite.mergeCompositeAttribute(this, attributeId);
  }
  collapseGeneralizationIntoParent(generalizationId) {
    restrGeneralization.collapseGeneralizationIntoParent(this, generalizationId);
  }
  collapseGeneralizationIntoChildren(generalizationId) {
    restrGeneralization.collapseGeneralizationIntoChildren(this, generalizationId);
  }
  substituteGeneralization(generalizationId) {
    restrGeneralization.substituteGeneralization(this, generalizationId);
  }
  translateEntity(entityId) {
    transEntity.translateEntity(this, entityId);
  }
  translateManyToManyRelationship(relationshipId) {
    transManyToMany.translateManyToManyRelationship(this, relationshipId);
  }
  translateOneToManyTypeARelationship(relationshipId) {
    transOneToMany.translateOneToManyTypeARelationship(this, relationshipId);
  }
  translateOneToManyTypeBRelationship(relationshipId, createTable = true) {
    transOneToMany.translateOneToManyTypeBRelationship(this, relationshipId, createTable);
  }
  translateOneToOneTypeARelationship(relationshipId) {
    transOneToOne.translateOneToOneTypeARelationship(this, relationshipId);
  }
  translateOneToOneTypeBRelationship(relationshipId, first = true) {
    transOneToOne.translateOneToOneTypeBRelationship(this, relationshipId, first);
  }
  translateOneToOneTypeCRelationship(relationshipId, createTable = true, first = true) {
    transOneToOne.translateOneToOneTypeCRelationship(this, relationshipId, createTable, first);
  }
  toERCode() {
    let code = '';

    const entities = this.getItemsWhere(i => i instanceof Entity);
    if(entities.length) {
      code += '/* Entities */\n';
      for(let entity of entities)
        code += entity.toERCode() + '\n';
      code += '\n';
    }

    const relationships = this.getItemsWhere(i => i instanceof Relationship);
    if(relationships.length) {
      code += '/* Relationships */\n';
      for(let relationship of relationships)
        code += relationship.toERCode() + '\n';
      code += '\n';
    }

    const generalizations = this.getItemsWhere(i => i instanceof Generalization);
    if(generalizations.length)
      code += '/* Generalizations */\n';
    for(let generalization of generalizations)
      code += generalization.toERCode() + '\n';

    return code.trim();
  }
  toSQLCode() {
    let code = '';

    const tables = this.getItemsWhere(i => i instanceof Table);
    if(tables.length) {
      code += '/* Tables */\n';
      for(let table of tables)
        code += table.toSQLCode() + '\n';
      code += '\n';
    }

    return code.trim();
  }
}